import { FC, useEffect, useState } from "react"
import { useSentry } from "@heyhabito/sentry"
import config from "../../config.json"
import { referralParamKeys } from "@heyhabito/persist-referral-data"

import { SentryErrorBoundary } from "../shared-components/Shared/ErrorBoundary"
import { BaseHead, Base } from "../shared-components/Base/Base"

export const Head: FC = () => {
  return (
    <SentryErrorBoundary>
      <BaseHead
        metaTitle="Sign in"
        metaDescription=""
        canonicalUrl="https://www.habito.com/sign-in"
        noIndex={true}
        pageName="Sign in"
        intercom={false}
        config={config}
      />
    </SentryErrorBoundary>
  )
}

const SignIn: FC = () => {
  const { logToSentry } = useSentry()

  function redirectToAuthFlows(token: string | null): void {
    const existingParams = new URLSearchParams(window.location.search)
    const params = new URLSearchParams()

    if (token) params.append("token", token)
    ;[
      "destination",
      "marketingOptIn",
      "orientationId",
      ...referralParamKeys,
    ].forEach(key => {
      const value = existingParams.get(key)
      if (value !== null) {
        params.append(key, value)
      }
    })

    window.location.replace(
      config.authURL + "/commence-login?" + params.toString()
    )
  }

  const [isRegisteringToken, setIsRegisteringToken] = useState<boolean>(false)

  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      if (isRegisteringToken) return

      setIsRegisteringToken(true)

      let token = localStorage.getItem("habito/token")

      if (token === null) {
        fetch(config.apiBaseURL + "/sessions", {
          method: "POST",
        })
          .then(res => {
            if (res.status !== 200) {
              res.text().then(text => {
                logToSentry(
                  `sessions call returned status ${res.status}`,
                  new Error(text)
                )
              })
            } else {
              res.json().then(json => {
                token = json.token
                redirectToAuthFlows(token)
              })
            }
          })
          .catch(e => {
            logToSentry(`failed to call sessions endpoint`, e)
          })
      } else {
        redirectToAuthFlows(token)
      }
    }
  }, [isRegisteringToken, logToSentry])

  /**
   * We necessarily don't want to use WrappedBase here.
   * WrappedBase deals with either an already authenticated user or a user who needs a token.
   * Either way that isn't relevant for the sign-in page, because we're actually in the process
   * of authenticating.
   **/
  return (
    <SentryErrorBoundary>
      <Base />
    </SentryErrorBoundary>
  )
}

export default SignIn
